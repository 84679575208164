<template>
  <el-row class="page-style" :gutter="30">
    <el-col :xs="24" :sm="14" class="login-image">
      <section id="login-title" class="section">
        <div class="container mt-5">
          <h1 class="header title is-1">CondoRail,</h1>
          <h2 class="header title is-3 th">
            <!-- Finding Condominium nearby Train station. -->
            คอนโดมิเนียมใกล้สถานีรถไฟฟ้า BTS, MRT, และ Airport Link
          </h2>
          <br />
          <a id="get-started" href="/" class="button is-primary">
            <strong>Get Started</strong>
          </a>
        </div>

        <div class="credit">
          <span
            >Photo by
            <a
              class="has-text-info"
              href="https://unsplash.com/@kholopkin?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
              >Kyrylo Kholopkin</a
            >
            on
            <a
              class="has-text-info"
              href="https://unsplash.com/s/photos/bangkok-skytrain?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
              >Unsplash</a
            ></span
          >
        </div>
      </section>
    </el-col>
    <el-col :xs="24" :sm="10">
      <section id="login-desc" class="section">
        <div class="container">
          <!-- Main container -->
          <nav class="level pb-2">
            <!-- Left side -->
            <div class="level-left">
              <div class="level-item"></div>
            </div>

            <!-- Right side -->
            <div class="level-right">
              <p class="level-item">
                <a
                  id="sign-in"
                  class="button is-light is-size-6-7"
                  style=""
                  @click="signUserInGoogle"
                >
                  <i class="fa fa-google"></i>
                  <strong>
                    <i class="fab fa-google mr-2"></i>Sign in with
                    Google</strong
                  >
                </a>
              </p>
            </div>
          </nav>
          <br />

          <div
            class="header title is-3 mt-5 typewriter th"
            style="color: #353f49"
          >
            <!-- What you are looking for? -->
            กำลังมองหานี่อยู่รึเปล่า?
          </div>

          <el-row :gutter="50" class="pt-3">
            <el-col :span="5">
              <div class="feature-icon">
                <i class="fa fa-hat-wizard"></i>
                <div class="feature-desc th">แหล่งข้อมูล</div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="feature-icon">
                <i class="fa fa-map-marked-alt"></i>
                <div class="feature-desc th">แผนที่</div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="feature-icon">
                <i class="far fa-building"></i>
                <div class="feature-desc th">คอนโด</div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="feature-icon">
                <i class="fa fa-subway"></i>
                <div class="feature-desc th">รถไฟฟ้า</div>
              </div>
            </el-col>
            <el-col :span="4"></el-col>
          </el-row>

          <div id="feature-info">
            <img src="@/assets/site-preview.png" alt="" />
            <div class="overlay">
              <div class="title">Condominiums in Bangkok</div>
              <div class="subtitle">By BTS, MRT, and ARL</div>
            </div>
          </div>
        </div>
      </section>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    if (this.getLoginStatus) {
      router.push({
        name: "Home",
      });
    }
  },
  computed: {
    ...mapGetters(["getLoading", "getErrors", "getLoginStatus"]),
  },
  methods: {
    ...mapActions(["signUserInGoogle"]),
  },
};
</script>

<style lang="scss" scoped>
.login-image {
  height: 100vh;
  background-image: url("~@/assets/login.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  overflow: auto;
}

#login-title {
  padding: 18vw 8vw 0 12vw;

  .header {
    color: white !important;
    // -webkit-text-stroke-width: 0.1px;
    // -webkit-text-stroke-color: rgb(65, 65, 65);
  }

  #get-started {
    padding: 1.5rem 3rem;
    border-radius: 2rem;
    transition: 200ms ease-in-out;
  }

  .credit {
    text-align: center;
    padding-top: 25vh;
    color: white !important;
  }
}

#login-desc {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 9vw 5vw 0 5vw;

  #sign-in {
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    box-shadow: 5px 5px 20px rgba(83, 98, 145, 0.3);
    background-color: #4285f4;
    color: white !important;
    transition: 200ms ease-in-out;
  }

  #sign-in:hover {
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    box-shadow: 5px 5px 30px rgba(83, 98, 145, 0.4);
    background-color: #2c78f3;
    color: white !important;
    transition: 300ms ease-in-out;
  }

  .feature-icon {
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    text-align: center;
    line-height: 6rem;
    box-shadow: 7px 7px 35px 10px rgba(83, 98, 145, 0.1);
    font-size: 30px;

    .feature-desc {
      line-height: 4rem;
      font-size: 16px;
      font-weight: 500;
    }
  }

  #feature-info {
    margin-top: 11vh;
    // padding: 1rem;
    position: relative;

    img {
      opacity: 0.8;
      pointer-events: none;
    }
    .overlay {
      position: absolute;
      top: 32%;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(83, 98, 145, 0.25);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      text-align: left;

      .title {
        padding: 8% 10% 0% 50%;
        line-height: 1.4;
        font-size: 1.2vw;
        font-weight: 500;
        color: #353f49;
      }
      .subtitle {
        padding: 0 0 0 50%;
        font-size: 1vw;
        font-weight: 400;
        line-height: 1.5;
        color: #6c767e;
      }
    }
  }
}
</style>
